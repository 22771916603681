export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"name":"English","code":"en","iso":"en-US","file":"en.js","flag":"us"},{"name":"Russian","code":"ru","iso":"ru","file":"ru.js","flag":"ru"},{"name":"Hebrew","code":"he","iso":"he","file":"he.js","dir":"rtl","flag":"il"},{"name":"Dutch","code":"nl","iso":"nl","file":"nl.js","flag":"nl"},{"name":"Spanish","code":"es","iso":"es","file":"es.js","flag":"es"},{"name":"Arabic","code":"ar","iso":"ar","file":"ar.js","dir":"rtl","flag":"ae"},{"name":"Hungarian","code":"hu","iso":"hu","file":"hu.js","flag":"hu"},{"name":"Korean","code":"ko","iso":"ko","file":"ko.js","flag":"kr"},{"name":"French","code":"fr","iso":"fr","file":"fr.js","flag":"fr"},{"name":"Romanian","code":"ro","iso":"ro","file":"ro.js","flag":"ro"},{"name":"German","code":"de","iso":"de","file":"de.js","flag":"de"},{"name":"Turkish","code":"tr","iso":"tr","file":"tr.js","flag":"tr"},{"name":"Italian","code":"it","iso":"it","file":"it.js","flag":"it"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "locales/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_language","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  loadLanguagesAsync: true,
  SEO: false,
  normalizedLocales: [{"name":"English","code":"en","iso":"en-US","file":"en.js","flag":"us"},{"name":"Russian","code":"ru","iso":"ru","file":"ru.js","flag":"ru"},{"name":"Hebrew","code":"he","iso":"he","file":"he.js","dir":"rtl","flag":"il"},{"name":"Dutch","code":"nl","iso":"nl","file":"nl.js","flag":"nl"},{"name":"Spanish","code":"es","iso":"es","file":"es.js","flag":"es"},{"name":"Arabic","code":"ar","iso":"ar","file":"ar.js","dir":"rtl","flag":"ae"},{"name":"Hungarian","code":"hu","iso":"hu","file":"hu.js","flag":"hu"},{"name":"Korean","code":"ko","iso":"ko","file":"ko.js","flag":"kr"},{"name":"French","code":"fr","iso":"fr","file":"fr.js","flag":"fr"},{"name":"Romanian","code":"ro","iso":"ro","file":"ro.js","flag":"ro"},{"name":"German","code":"de","iso":"de","file":"de.js","flag":"de"},{"name":"Turkish","code":"tr","iso":"tr","file":"tr.js","flag":"tr"},{"name":"Italian","code":"it","iso":"it","file":"it.js","flag":"it"}],
  localeCodes: ["en","ru","he","nl","es","ar","hu","ko","fr","ro","de","tr","it"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "e",
  3: "n",
  4: ".",
  5: "j",
  6: "s",
  7: "\"",
  8: ":",
  9: "\"",
  10: ".",
  11: ".",
  12: "/",
  13: "l",
  14: "o",
  15: "c",
  16: "a",
  17: "l",
  18: "e",
  19: "s",
  20: "/",
  21: "e",
  22: "n",
  23: ".",
  24: "j",
  25: "s",
  26: "\"",
  27: ",",
  28: "\"",
  29: "r",
  30: "u",
  31: ".",
  32: "j",
  33: "s",
  34: "\"",
  35: ":",
  36: "\"",
  37: ".",
  38: ".",
  39: "/",
  40: "l",
  41: "o",
  42: "c",
  43: "a",
  44: "l",
  45: "e",
  46: "s",
  47: "/",
  48: "r",
  49: "u",
  50: ".",
  51: "j",
  52: "s",
  53: "\"",
  54: ",",
  55: "\"",
  56: "h",
  57: "e",
  58: ".",
  59: "j",
  60: "s",
  61: "\"",
  62: ":",
  63: "\"",
  64: ".",
  65: ".",
  66: "/",
  67: "l",
  68: "o",
  69: "c",
  70: "a",
  71: "l",
  72: "e",
  73: "s",
  74: "/",
  75: "h",
  76: "e",
  77: ".",
  78: "j",
  79: "s",
  80: "\"",
  81: ",",
  82: "\"",
  83: "n",
  84: "l",
  85: ".",
  86: "j",
  87: "s",
  88: "\"",
  89: ":",
  90: "\"",
  91: ".",
  92: ".",
  93: "/",
  94: "l",
  95: "o",
  96: "c",
  97: "a",
  98: "l",
  99: "e",
  100: "s",
  101: "/",
  102: "n",
  103: "l",
  104: ".",
  105: "j",
  106: "s",
  107: "\"",
  108: ",",
  109: "\"",
  110: "e",
  111: "s",
  112: ".",
  113: "j",
  114: "s",
  115: "\"",
  116: ":",
  117: "\"",
  118: ".",
  119: ".",
  120: "/",
  121: "l",
  122: "o",
  123: "c",
  124: "a",
  125: "l",
  126: "e",
  127: "s",
  128: "/",
  129: "e",
  130: "s",
  131: ".",
  132: "j",
  133: "s",
  134: "\"",
  135: ",",
  136: "\"",
  137: "a",
  138: "r",
  139: ".",
  140: "j",
  141: "s",
  142: "\"",
  143: ":",
  144: "\"",
  145: ".",
  146: ".",
  147: "/",
  148: "l",
  149: "o",
  150: "c",
  151: "a",
  152: "l",
  153: "e",
  154: "s",
  155: "/",
  156: "a",
  157: "r",
  158: ".",
  159: "j",
  160: "s",
  161: "\"",
  162: ",",
  163: "\"",
  164: "h",
  165: "u",
  166: ".",
  167: "j",
  168: "s",
  169: "\"",
  170: ":",
  171: "\"",
  172: ".",
  173: ".",
  174: "/",
  175: "l",
  176: "o",
  177: "c",
  178: "a",
  179: "l",
  180: "e",
  181: "s",
  182: "/",
  183: "h",
  184: "u",
  185: ".",
  186: "j",
  187: "s",
  188: "\"",
  189: ",",
  190: "\"",
  191: "k",
  192: "o",
  193: ".",
  194: "j",
  195: "s",
  196: "\"",
  197: ":",
  198: "\"",
  199: ".",
  200: ".",
  201: "/",
  202: "l",
  203: "o",
  204: "c",
  205: "a",
  206: "l",
  207: "e",
  208: "s",
  209: "/",
  210: "k",
  211: "o",
  212: ".",
  213: "j",
  214: "s",
  215: "\"",
  216: ",",
  217: "\"",
  218: "f",
  219: "r",
  220: ".",
  221: "j",
  222: "s",
  223: "\"",
  224: ":",
  225: "\"",
  226: ".",
  227: ".",
  228: "/",
  229: "l",
  230: "o",
  231: "c",
  232: "a",
  233: "l",
  234: "e",
  235: "s",
  236: "/",
  237: "f",
  238: "r",
  239: ".",
  240: "j",
  241: "s",
  242: "\"",
  243: ",",
  244: "\"",
  245: "r",
  246: "o",
  247: ".",
  248: "j",
  249: "s",
  250: "\"",
  251: ":",
  252: "\"",
  253: ".",
  254: ".",
  255: "/",
  256: "l",
  257: "o",
  258: "c",
  259: "a",
  260: "l",
  261: "e",
  262: "s",
  263: "/",
  264: "r",
  265: "o",
  266: ".",
  267: "j",
  268: "s",
  269: "\"",
  270: ",",
  271: "\"",
  272: "d",
  273: "e",
  274: ".",
  275: "j",
  276: "s",
  277: "\"",
  278: ":",
  279: "\"",
  280: ".",
  281: ".",
  282: "/",
  283: "l",
  284: "o",
  285: "c",
  286: "a",
  287: "l",
  288: "e",
  289: "s",
  290: "/",
  291: "d",
  292: "e",
  293: ".",
  294: "j",
  295: "s",
  296: "\"",
  297: ",",
  298: "\"",
  299: "t",
  300: "r",
  301: ".",
  302: "j",
  303: "s",
  304: "\"",
  305: ":",
  306: "\"",
  307: ".",
  308: ".",
  309: "/",
  310: "l",
  311: "o",
  312: "c",
  313: "a",
  314: "l",
  315: "e",
  316: "s",
  317: "/",
  318: "t",
  319: "r",
  320: ".",
  321: "j",
  322: "s",
  323: "\"",
  324: ",",
  325: "\"",
  326: "i",
  327: "t",
  328: ".",
  329: "j",
  330: "s",
  331: "\"",
  332: ":",
  333: "\"",
  334: ".",
  335: ".",
  336: "/",
  337: "l",
  338: "o",
  339: "c",
  340: "a",
  341: "l",
  342: "e",
  343: "s",
  344: "/",
  345: "i",
  346: "t",
  347: ".",
  348: "j",
  349: "s",
  350: "\"",
  351: "}",
}

export const localeMessages = {
  'en.js': () => import('../../locales/en.js' /* webpackChunkName: "lang-en.js" */),
  'ru.js': () => import('../../locales/ru.js' /* webpackChunkName: "lang-ru.js" */),
  'he.js': () => import('../../locales/he.js' /* webpackChunkName: "lang-he.js" */),
  'nl.js': () => import('../../locales/nl.js' /* webpackChunkName: "lang-nl.js" */),
  'es.js': () => import('../../locales/es.js' /* webpackChunkName: "lang-es.js" */),
  'ar.js': () => import('../../locales/ar.js' /* webpackChunkName: "lang-ar.js" */),
  'hu.js': () => import('../../locales/hu.js' /* webpackChunkName: "lang-hu.js" */),
  'ko.js': () => import('../../locales/ko.js' /* webpackChunkName: "lang-ko.js" */),
  'fr.js': () => import('../../locales/fr.js' /* webpackChunkName: "lang-fr.js" */),
  'ro.js': () => import('../../locales/ro.js' /* webpackChunkName: "lang-ro.js" */),
  'de.js': () => import('../../locales/de.js' /* webpackChunkName: "lang-de.js" */),
  'tr.js': () => import('../../locales/tr.js' /* webpackChunkName: "lang-tr.js" */),
  'it.js': () => import('../../locales/it.js' /* webpackChunkName: "lang-it.js" */),
}
